// @flow
import * as React from "react";

import AuthService from "services/auth";

const MAIN_SITE_ORIGIN = process.env.REACT_APP_MAIN_SITE_ORIGIN;

const ManagerMain = React.lazy(() => import("./Manager"));
const AffiliateMain = React.lazy(() => import("./Affiliate"));

function Main(): React.Node {
  const isAuthenticated = AuthService.isAuthenticated();

  if (isAuthenticated === false) {
    window.location.replace(`${MAIN_SITE_ORIGIN}/login`);

    return null;
  }

  const claims = AuthService.getClaims();

  switch (claims.rol) {
    case "adm":
    case "him":
    case "ham":
    case "inm":
    case "afm":
      return <ManagerMain />;
    case "aff":
      return <AffiliateMain />;
    default:
      return null;
  }
}

export default Main;
