// @flow
import type { Claims } from "overclick";

const MAIN_SITE_HOSTNAME = process.env.REACT_APP_MAIN_SITE_HOSTNAME;

function getToken(): string {
  const name = "id_token=";

  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");

  for (let cookie of cookieArray) {
    while (cookie.startsWith(" ")) {
      cookie = cookie.slice(1);
    }

    if (cookie.startsWith(name)) {
      return cookie.slice(name.length);
    }
  }

  return "";
}

function setToken(token: string, maxAge: number): void {
  document.cookie = `id_token=${token}; max-age=${maxAge}; path=/; domain=${MAIN_SITE_HOSTNAME}`;
}

function getClaims(token: string): Claims {
  const base64Url = token.split(".", 3)[1] || "";

  const base64 = decodeURIComponent(
    atob(base64Url).replace(/(.)/g, (_, char) => {
      return "%" + ("00" + char.charCodeAt(0).toString(16)).slice(-2);
    }),
  );

  return JSON.parse(base64 || "{}");
}

const AuthService = {
  getToken(): string {
    return getToken();
  },
  getClaims(): Claims {
    return getClaims(getToken());
  },
  logout(): void {
    setToken("", -1);
  },
  isAuthenticated(): boolean {
    return Boolean(getToken());
  },
};

export default AuthService;
